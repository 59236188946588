import { useEffect, useRef } from 'react'
import { createPagination, withRepeats, type Pagination } from './pagination'
import styles from './Pager.module.scss'
import { ensureNumber } from '~/types/strict-null-helpers'

interface PagerProps {
	items: unknown[]
	realIndex: number
	repeatCount: number
	onSlideTo: (index: number) => void
}

export function Pager({ items, realIndex, onSlideTo, repeatCount = 1 }: PagerProps) {
	const containerRef = useRef<HTMLDivElement>(null)
	const pagination = useRef<Pagination | null>(null)
	const totalCount = items.length / repeatCount

	if (!pagination.current) {
		let paginationInstance = createPagination(8, totalCount, 2)
		if (repeatCount > 1) {
			paginationInstance = withRepeats(paginationInstance, totalCount)
		}
		pagination.current = paginationInstance
	}

	useEffect(() => {
		let paginationInstance = createPagination(8, totalCount, 2)
		if (repeatCount > 1) {
			paginationInstance = withRepeats(paginationInstance, totalCount)
		}
		pagination.current = paginationInstance
		pagination.current.setSelectedIndex(realIndex)
	}, [items, totalCount, repeatCount, realIndex])

	pagination.current.setSelectedIndex(realIndex)

	useBoundarySlotAnimator(containerRef.current, pagination.current)

	const handleClick = (_item: unknown, slotIndex: number) => {
		const slideToIndex = ensureNumber(pagination.current?.getItemIndexFromSlot(slotIndex))
		onSlideTo(slideToIndex)
	}

	if (pagination.current.items.length === 1) return null

	return (
		<>
			<div ref={containerRef} className={styles.pager}>
				{pagination.current.items.map((item: unknown, index: number) => (
					<span
						key={index}
						onClick={() => {
							handleClick(item, index)
						}}
						className={pagination.current?.pageIndex === index ? styles.active : ''}
					/>
				))}
			</div>
		</>
	)
}

function useBoundarySlotAnimator(container: HTMLDivElement | null, pagination: Pagination) {
	const prevSelectedIndexRef = useRef(-1)
	const prevSlotRef = useRef(-1)
	useEffect(() => {
		const currentSelectedIndex = pagination.selectedIndex ?? 0
		if (prevSlotRef.current === pagination.pageIndex && currentSelectedIndex !== prevSelectedIndexRef.current) {
			const child = container?.children[ensureNumber(pagination.pageIndex)]
			child?.classList.remove(styles.active)
			setTimeout(() => {
				child?.classList.add(styles.active)
			}, 100)
		}

		prevSlotRef.current = ensureNumber(pagination.pageIndex)
		prevSelectedIndexRef.current = currentSelectedIndex
	})
}
