interface ConditionalProps<T extends React.ElementType> {
	condition: boolean
	as: T
	children?: React.ReactNode
	fallback?: React.ReactNode
	FallbackComponent?: T
}

/**
 * A conditional component that conditionally renders content given a specific condition.
 * Useful to avoid imperative conditional logic.
 * 
 * For example, consider the imperative logic:

  {isSomeFeatureEnabled ? (
    <SomeFeatureSpecificComponent product={product} user={user} />
  ) : (
    <SomeFallbackComponent product={product} user={user} />
  )}

 * To clean up the JSX, you can use this component to achieve the same result declaratively:

  <Conditional 
    condition={isSomeFeatureEnabled} 
    as="SomeFeatureSpecificComponent"
    product={product}
    user={user}
    fallback={<SomeFallbackComponent product={product} user={user} />}
  />

 * or specifying a FallbackComponent since the props are the same in the `as` component: 

  <Conditional 
    condition={isSomeFeatureEnabled} 
    as="SomeFeatureSpecificComponent"
    product={product}
    user={user}
    FallbackComponent={SomeFallbackComponent}
  />

 */
export function Conditional<T extends React.ElementType>({
	condition,
	as,
	children,
	fallback,
	FallbackComponent,
	...rest
}: ConditionalProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ConditionalProps<T>>) {
	const Element: any = as // eslint-disable-line @typescript-eslint/no-explicit-any -- TS checks the `as` type externally in the function signature
	const Fallback: any = FallbackComponent // eslint-disable-line @typescript-eslint/no-explicit-any -- TS checks the `as` type externally in the function signature
	const fallbackNode = FallbackComponent ? <Fallback {...rest}>{children}</Fallback> : fallback
	return condition ? <Element {...rest}>{children}</Element> : <>{fallbackNode}</>
}
