interface Item {
	index: number
	slotIndex: number
}

export function createPagination(maxWindowSize: number, totalCount: number, boundaryCount = 0) {
	let previousSelectedIndex = 0
	let selectedIndex = 0
	let pageIndex = 0
	let windowStartIndex = 0
	let items: Item[] = []
	const windowSize = Math.min(maxWindowSize, totalCount)

	function getAction() {
		const offsetStartIndex = windowStartIndex + boundaryCount
		const offsetStopIndex = windowStartIndex + (windowSize - boundaryCount)
		const hasMoreItemsAfter = totalCount - (selectedIndex + boundaryCount) > 0
		const hasMoreItemsBefore = selectedIndex - boundaryCount >= 0

		if (selectedIndex === previousSelectedIndex) return undefined
		const direction = selectedIndex - previousSelectedIndex

		// Loop to end
		if (direction > 0 && selectedIndex === totalCount - 1) {
			return 'reset-stop'
		}

		// Increment within window range
		if (direction > 0 && selectedIndex >= offsetStopIndex && hasMoreItemsAfter) {
			return 'increment'
		}

		// Loop to start after hitting end
		if (direction < 0 && selectedIndex === 0) {
			return 'reset-start'
		}

		// Decrement
		if (direction < 0 && selectedIndex < offsetStartIndex && hasMoreItemsBefore) {
			return 'decrement'
		}
		return undefined
	}

	function updateWindow() {
		const action = getAction()
		switch (action) {
			case 'increment':
				windowStartIndex += 1
				break
			case 'decrement':
				windowStartIndex -= 1
				break
			case 'reset-start':
				windowStartIndex = 0
				break
			case 'reset-stop':
				windowStartIndex = totalCount - windowSize
				break
		}

		items = []
		for (let i = 0; i < windowSize; i += 1) {
			items.push({
				index: windowStartIndex + i,
				slotIndex: i,
			})
		}

		const slot = items.find((item) => item.index === selectedIndex)
		pageIndex = slot ? slot.slotIndex : 0
	}

	function setSelectedIndex(index: number) {
		selectedIndex = index
		updateWindow()
		previousSelectedIndex = selectedIndex
	}

	return {
		windowSize,
		get selectedIndex() {
			return selectedIndex
		},
		get pageIndex() {
			return pageIndex
		},
		get windowStartIndex() {
			return windowStartIndex
		},
		get items() {
			return items
		},
		getItemIndexFromSlot: (slotIndex: number) => items[slotIndex].index,
		setSelectedIndex,
	}
}

export function withRepeats(pagination: Pagination, totalCount: number) {
	let selectedIndexWithRepeats = 0
	return {
		windowSize: pagination.windowSize,
		get selectedIndex() {
			return pagination.selectedIndex
		},
		get pageIndex() {
			return pagination.pageIndex
		},
		get windowStartIndex() {
			return pagination.windowStartIndex
		},
		get items() {
			return pagination.items
		},
		setSelectedIndex: (index: number) => {
			selectedIndexWithRepeats = index
			pagination.setSelectedIndex(index % totalCount)
		},
		getItemIndexFromSlot: (slotIndex: number) => {
			const { index } = pagination.items[slotIndex]
			const offset = Math.floor(selectedIndexWithRepeats / totalCount) * totalCount
			return offset + index
		},
	}
}

export type Pagination = ReturnType<typeof createPagination>
