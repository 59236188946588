'use client'

/* eslint-disable local-rules/disallow-untranslated-literals-in-jsx */
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'

import { useMemo, useRef, useState, useEffect, useCallback, memo } from 'react'
import clsx from 'clsx'
import type SwiperCore from 'swiper'
import { Swiper, SwiperSlide, type SwiperClass } from 'swiper/react'
import { Grid, Keyboard, Navigation, Pagination } from 'swiper/modules'
import { type SwiperOptions } from 'swiper/types'
import type { ContentCarouselModule, ContentCarouselSlide } from '~/lib/client-server/cms/modules/content-carousel'
import { ButtonLink } from '@ua-digital-commerce/ua-web-components/components/ButtonLink'
import ElementTree from '~/components/shared/ElementTree'
import { ProductSlide, ContentSlide, SkeletonSlide } from './CarouselSlide'
import { useGetCMSModuleProductTiles } from '~/components/hooks/cms/uci/useGetCMSModuleTiles'
import { useLocale } from '~/components/hooks/useLocale'
import { useMediaQuery } from '~/components/hooks/useMediaQuery'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { isString } from '~/lib/utils'
import styles from './ContentCarousel.module.scss'
import CarouselNavigation from './CarouselNavigation/CarouselNavigation'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { Pager } from '~/components/pages/ProductDetail/ProductDetailContent/ProductInformation/ProductImages/HorizontalImageSlider/Pager'

import mediaQueries from '~/lib/client-only/media-queries'
import { ContentCarouselSwiperSkeleton } from '~/components/cms/ContentCarousel/ContentCarouselSkeleton'
import { Conditional } from '~/components/shared/Conditional/Conditional'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'

const CarouselHeadline = ({ title, titleStyle, locale }) => {
	return (
		<div className={clsx(styles.content__carousel__headline, titleStyle !== 'headline4' && titleStyle)}>
			{isString(title) ? <p>{title}</p> : <ElementTree element={title} locale={locale} />}
		</div>
	)
}

const CarouselDescription = ({ description, locale }) => {
	return (
		<div>{isString(description) ? <p>{description}</p> : <ElementTree element={description} locale={locale} />}</div>
	)
}

// this is really extends Omit<ContentCarouselModule, 'type'>  but the storybook docs wouldn't work.
export interface ContentCarouselProps {
	/** The theme of the individual module. This can be `light` or `dark` or dark on mobile or desktop only with the default being `light` */
	theme: ContentCarouselModule['theme']

	/** The slide layout drives how the slide starts on mobile. It can be a grid (2x2), stacked (1x4) or a carousel. */
	layout: ContentCarouselModule['layout']

	/** The maximum number of slides shown in a row, can be 1-4 */
	maxSlidesPerRow: ContentCarouselModule['maxSlidesPerRow']

	/** Slide content to show */
	slides: ContentCarouselModule['slides']

	/** Additional settings for the slide, which can center text, style headlines and descriptions */
	settings?: ContentCarouselModule['settings']

	/** Heading text of the module */
	title?: ContentCarouselModule['title']

	/** Secondary text of the module */
	description?: ContentCarouselModule['description']

	/** Optional link to direct user to a landing page */
	callsToAction?: ContentCarouselModule['callsToAction']

	/** Optional mobile settings */
	mobile?: ContentCarouselModule['mobile']

	/** Optional name of module set from CMS */
	moduleName?: ContentCarouselModule['name']

	/** Required id set and created by CMS */
	moduleId: ContentCarouselModule['id']

	/** The index position of the module. This is used to send a position to the images to determine priority loading */
	index: number

	/** The variant to render */
	variant?: 'default' | 'large-grid-layout'
}

/** Content Carousel is an all-encompassing content and product carousel. */
function ContentCarousel({
	theme = 'light',
	layout,
	maxSlidesPerRow,
	slides,
	settings,
	title,
	description,
	callsToAction,
	moduleName,
	moduleId,
	index,
	variant = 'default',
}: ContentCarouselProps) {
	const locale = useLocale()
	const swiperRef = useRef<SwiperCore | null>(null)
	const [swiperInstance, setSwiper] = useState<SwiperClass | null>(null)
	const [isInitializing, setIsInitializing] = useState(true)

	const { analyticsManager } = useAnalytics()

	const handleInit = useCallback((swiper: SwiperClass) => {
		setSwiper(swiper)
		setIsInitializing(false)
	}, [])
	const { carouselItems } = useGetCMSModuleProductTiles(slides)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSlideChangeAnalytics = ({ direction }: { direction: 'next' | 'previous' }) => {
		analyticsManager.trackContentCarouselNavEvent({ module: 'Content Carousel', direction, moduleName, moduleId })
	}

	const { headerHeight } = useLayoutMeasurement()
	const [carouselAnalyticsFired, setCarouselAnalyticsFired] = useState(false)

	// Track whether Carousel is visible on page, fire analytics, toggle state so it only fires when scrolled into view once.
	const carouselRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const ugcElement = carouselRef.current
		const carouselAnalyticsPayload = {
			moduleName,
			moduleId,
			type: 'Content Carousel',
		}
		if (!headerHeight || !ugcElement || carouselAnalyticsFired) return undefined
		const ugcObserver = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting && !carouselAnalyticsFired) {
					analyticsManager.fireCarouselScrollInView(carouselAnalyticsPayload)
					setCarouselAnalyticsFired(true)
				}
			},
			{
				threshold: 0.5,
				rootMargin: `-${headerHeight}px 0px -${headerHeight}px 0px`,
			},
		)
		ugcObserver.observe(ugcElement)
		return () => {
			ugcObserver.unobserve(ugcElement)
		}
	}, [headerHeight, carouselAnalyticsFired, analyticsManager, moduleName, moduleId])

	const baseSwiperParams: SwiperOptions = useMemo(() => {
		const baseParams = {
			modules: [Grid, Keyboard, Navigation, Pagination],
			keyboard: {
				enabled: true,
			},
			navigation: {
				enabled: true,
				prevEl: '.prev',
				nextEl: '.next',
			},
			pagination: {
				clickable: true,
				el: '.swiper-pagination',
				// on mobile, dynamic pagination should show if there are more than 5 slides
				dynamicBullets: slides.length > 5,
				dynamicMainBullets: slides.length < 5 ? 5 : 3,
			},
			slidesOffsetBefore: 16,
			slidesOffsetAfter: 16,
			slidesPerView: 1.804, // extra specific for designs.
			spaceBetween: 8,
			watchOverflow: true,
			onSlideNextTransitionEnd: () => handleSlideChangeAnalytics({ direction: 'next' }),
			onSlidePrevTransitionEnd: () => handleSlideChangeAnalytics({ direction: 'previous' }),
			onSwiper: (swiper: SwiperCore) => (swiperRef.current = swiper),
			onInit: handleInit,
			updateOnImagesReady: true,
			breakpoints: {
				600: {
					slidesPerView: 2.5,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: maxSlidesPerRow > 3 ? 3.5 : maxSlidesPerRow,
					spaceBetween: 16,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					pagination: {
						// on desktop, dynamic pagination should show if there are more than 4 slides
						dynamicBullets: slides.length > 4,
						dynamicMainBullets: 3,
					},
				},
				1024: {
					// using 1024 here instead of 1023 because of how the smaller breaks are handled, styling lg-up
					slidesPerView: maxSlidesPerRow,
					spaceBetween: 44,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					centeredSlidesBounds: true,
					pagination: {
						// on desktop, dynamic pagination should show if there are more than 4 slides
						dynamicBullets: slides.length > 4,
						dynamicMainBullets: 3,
					},
				},
			},
		}

		// Apply large grid layout if the variant is enabled
		if (variant === 'large-grid-layout') {
			return {
				...baseParams,
				...getSwiperPropsForLargeGridLayout(maxSlidesPerRow, slides.length),
			}
		}

		return baseParams
	}, [slides.length, handleInit, maxSlidesPerRow, variant, handleSlideChangeAnalytics])

	// Apply additional settings based on carouselStyle
	const additionalSwiperParams: SwiperOptions | undefined = useMemo(() => {
		// add a new case for each `carouselStyle` that needs additional params.
		switch (settings?.carouselStyle) {
			case 'guided-shopping':
				return {
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					slidesPerView: 2.2,
					breakpoints: {
						600: {
							slidesPerView: maxSlidesPerRow > 3 ? 3.2 : maxSlidesPerRow,
							spaceBetween: 8,
						},
						1024: {
							slidesPerView: maxSlidesPerRow,
							spaceBetween: 16,
						},
					},
				}
		}
		return undefined
	}, [maxSlidesPerRow, settings?.carouselStyle])

	// Combine baseSwiperParams and any additional settings -- the additional settings do override base if provided. IE: if you add `breakpoints` all breakpoint settings are overridden.
	const swiperParams: SwiperOptions = useMemo(
		() => ({ ...baseSwiperParams, ...additionalSwiperParams }),
		[additionalSwiperParams, baseSwiperParams],
	)

	/**
	 * Swiper Pagination, custom pagination and navigation
	 */
	const handlePrev = useCallback(
		() => (variant === 'large-grid-layout' ? swiperInstance?.slidePrev() : undefined),
		[swiperInstance, variant],
	)
	const handleNext = useCallback(
		() => (variant === 'large-grid-layout' ? swiperInstance?.slideNext() : undefined),
		[swiperInstance, variant],
	)

	// tracking and setting index of swiper
	const [realIndex, setRealIndex] = useState(0)
	const handleRealIndexChange = useCallback((swiper: SwiperClass) => setRealIndex(swiper.realIndex), [])

	// tracking breakpoint settings for swiper
	const { currentBreakpoint, params } = swiperInstance || {}

	// checking for settings at current breakpoint
	const breakpointSettings = params?.breakpoints?.[currentBreakpoint] || {}
	const breakpointSlidesPerView =
		breakpointSettings.slidesPerView === 'auto' ? 1 : Math.round(breakpointSettings.slidesPerView || 1)
	const slideRepeatCount = breakpointSlidesPerView === 1 ? 1 : Math.max(0, slides.length - breakpointSlidesPerView)

	const renderNavigation = useMemo(() => {
		// Look at breakpoint settings for navigation, if not defined, use variant default
		const hasNavigationSettings = breakpointSettings.navigation !== undefined ? !!breakpointSettings.navigation : false
		const isPrevDisabled = realIndex === 0
		// if large grid layout, follow the count matching, if not, passing undefined lets it default to how swiper handles it, without passing undefined, it double fires the navigation
		const isNextDisabled = variant === 'large-grid-layout' ? realIndex === slideRepeatCount : undefined

		return (
			<Conditional
				condition={hasNavigationSettings || variant === 'default'}
				as={CarouselNavigation}
				onPrev={handlePrev}
				onNext={handleNext}
				isPrevDisabled={isPrevDisabled}
				isNextDisabled={isNextDisabled}
			/>
		)
	}, [breakpointSettings.navigation, realIndex, variant, slideRepeatCount, handlePrev, handleNext])

	const renderPagination = useMemo(() => {
		const handleSlideTo = (index: number) => swiperInstance?.slideToLoop(index)
		// Look at breakpoint settings for pagination, if not defined, use variant default
		const hasPaginationSettings = breakpointSettings.pagination !== undefined ? !!breakpointSettings.pagination : true

		return (
			<Conditional
				condition={hasPaginationSettings && variant === 'large-grid-layout'}
				as={Pager}
				items={carouselItems}
				realIndex={realIndex}
				onSlideTo={handleSlideTo}
				repeatCount={slideRepeatCount}
				fallback={<div className="swiper-pagination" />}
			/>
		)
	}, [breakpointSettings.pagination, variant, carouselItems, realIndex, slideRepeatCount, swiperInstance])

	// There is a situation where it can be stacked on mobile and still meet the requirements to be a slider on desktop. We want to ensure that if we are in mobile view, the selected type is the source of truth, rather than implied from data
	const isMobilePhoneDevice = useMediaQuery(mediaQueries.tabletDown)
	const isCarousel = layout === 'carousel' || (!isMobilePhoneDevice && maxSlidesPerRow < slides.length)

	useEffect(() => {
		function extractCarouselAssetIds(slides: ContentCarouselSlide[]) {
			const assetNames: string[] = []
			slides.forEach((slide, index) => {
				if (slide.type === 'content') {
					const assetId = slide.isNumbered ? `${index}_isNumberedSlide` : slide.image?.id
					if (assetId) {
						// Ensure assetId is not undefined before pushing
						assetNames.push(assetId)
					}
				}
				if (slide.type === 'product') {
					const faceOutColor = slide?.faceOutColor || 'DEFAULT'
					assetNames.push(`PRODUCT_${slide.productId}_COLOR_${faceOutColor}`)
				}
			})

			return assetNames // Return object with assetNames and productIds
		}
		if (slides.length > 0) {
			analyticsManager.registerTrackableContent({
				type: 'Content Carousel',
				names: extractCarouselAssetIds(slides),
				moduleId,
			})
		}
	}, [moduleId, analyticsManager, slides])

	// priority is needed for image load priority, however it is showing everything is priority and that is not accurate
	const priority = index === 0

	/** Content Adjustments / Settings */
	const hasValidCallsToAction = !!callsToAction?.length && callsToAction?.filter((link) => link?.isEnabled)?.length > 0

	/** Create common style classes since we are using in two locations */
	const carouselStyleClasses = clsx(styles.carousel__wrapper, {
		[styles.carousel__slider]: isCarousel,
		[styles.carousel__grid]: layout === 'grid',
		[styles['slide__content--center']]: settings?.isCenter,
		[styles['slide__content--numbered']]: settings?.isNumbered,
		[styles['slide__content--guided-shopping']]: settings?.carouselStyle === 'guided-shopping', // has image hover animations
	})
	const headerStyleClasses = clsx(styles.content__carousel__header, {
		[styles['content__carousel__header--center']]: settings?.isCenter,
		[styles['content__carousel__header--numbered']]: settings?.isNumbered,
	})

	/** Simplified rendering of the slides. This can be improved */
	const renderSlideContent = useCallback(
		(slide, index, isCarousel) => {
			if (isInitializing && isCarousel) {
				return isCarousel ? (
					<ContentCarouselSwiperSkeleton className={carouselStyleClasses} slideCount={maxSlidesPerRow} style="grid" />
				) : (
					<SkeletonSlide key={`${moduleName}_${index}`} />
				)
			}
			if (slide.type === 'product') {
				return isCarousel ? (
					<SwiperSlide key={index}>
						<ProductSlide productSlideData={slide} />
					</SwiperSlide>
				) : (
					<ProductSlide productSlideData={slide} key={`${moduleName}_${index}`} />
				)
			}

			return isCarousel ? (
				<SwiperSlide key={index}>
					<ContentSlide
						callsToAction={slide?.callsToAction}
						description={slide?.description}
						icon={slide?.icon}
						image={slide?.image}
						index={index}
						isNumbered={slide?.isNumbered}
						layout={slide?.layout}
						locale={locale}
						priority={priority}
						title={slide?.title}
						module={{ moduleId, moduleName }}
						variant={variant}
					/>
				</SwiperSlide>
			) : (
				<ContentSlide
					key={`${moduleName}_${index}`}
					callsToAction={slide?.callsToAction}
					description={slide?.description}
					icon={slide?.icon}
					image={slide?.image}
					index={index}
					isNumbered={slide?.isNumbered}
					layout={slide?.layout}
					locale={locale}
					priority={priority}
					title={slide?.title}
					module={{ moduleId, moduleName }}
					variant={variant}
				/>
			)
		},
		[carouselStyleClasses, isInitializing, locale, maxSlidesPerRow, moduleId, moduleName, priority, variant],
	)

	const carouselRender = useMemo(() => {
		if (carouselItems?.length > 0) {
			return (
				<Swiper className={carouselStyleClasses} {...swiperParams} onRealIndexChange={handleRealIndexChange}>
					{carouselItems.map((slide, index) => renderSlideContent(slide, index, isCarousel))}
					{renderNavigation}
					{renderPagination}
				</Swiper>
			)
		}

		return <ContentCarouselSwiperSkeleton className={carouselStyleClasses} slideCount={maxSlidesPerRow} style="grid" />
	}, [
		carouselItems,
		carouselStyleClasses,
		isCarousel,
		maxSlidesPerRow,
		renderSlideContent,
		swiperParams,
		handleRealIndexChange,
		renderNavigation,
		renderPagination,
	])

	const contentGridRender = useMemo(() => {
		if (carouselItems?.length > 0) {
			return (
				<div className={carouselStyleClasses}>
					{carouselItems?.map((slide, index) => renderSlideContent(slide, index, isCarousel))}
				</div>
			)
		}

		return <ContentCarouselSwiperSkeleton slideCount={maxSlidesPerRow} lean={true} style="grid" />
	}, [carouselItems, carouselStyleClasses, isCarousel, maxSlidesPerRow, renderSlideContent])

	if (!slides || slides.length === 0) return null

	return (
		<div
			className={clsx(styles.content__carousel, styles[variant])}
			role="region"
			aria-label={`${moduleName} slider`}
			data-theme-cms={theme}
			ref={carouselRef}
		>
			<div className={styles.content__carousel__inner}>
				{(title || description) && (
					<div className={headerStyleClasses}>
						{!!title && <CarouselHeadline title={title} titleStyle={settings?.titleStyle} locale={locale} />}
						{!!description && <CarouselDescription description={description} locale={locale} />}
					</div>
				)}

				{/** Conditionally renders the swiper or grid panel based on passed in configurations */}
				{isCarousel ? carouselRender : contentGridRender}

				{/** TODO: Look to convert this to use the CallsToAction Component, currently we need the object to have links for this to work. */}
				{hasValidCallsToAction && (
					<ul className={styles['carousel__call-to-action']}>
						{callsToAction
							.filter((link) => link.isEnabled)
							.map((link, index) => (
								<li key={index}>
									<ButtonLink as={LocaleLink} href={link.url}>
										{link.text}
									</ButtonLink>
								</li>
							))}
					</ul>
				)}
			</div>
		</div>
	)
}

export default memo(ContentCarousel)

function getSwiperPropsForLargeGridLayout(maxSlidesPerView: number, slideCount: number) {
	return {
		navigation: false,
		spaceBetween: 16,
		slidesPerView: 1.15,
		breakpoints: {
			600: {
				slidesPerView: 1.15,
			},
			768: {
				// done this way vs adding the logic to the 'enabled' because of how navigation is referenced in the swiper code and our implementation
				...(slideCount > maxSlidesPerView && {
					navigation: {
						enabled: true,
						prevEl: '.prev',
						nextEl: '.next',
					},
				}),
				pagination: slideCount > maxSlidesPerView, // setting to false if there are less
				slidesPerView: maxSlidesPerView > 3 ? 3.5 : maxSlidesPerView,
				spaceBetween: 16,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1024: {
				// done this way vs adding the logic to the 'enabled' because of how navigation is referenced in the swiper code and our implementation
				...(slideCount > maxSlidesPerView && {
					navigation: {
						enabled: true,
						prevEl: '.prev',
						nextEl: '.next',
					},
				}),
				spaceBetween: 16,
				pagination: slideCount > maxSlidesPerView,
				slidesPerView: maxSlidesPerView,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
		},
	}
}
