import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

function SkeletonSlide({ lean = false }) {
	return (
		<>
			<UaSkeleton variant="rectangular" />
			<UaSkeleton variant="rectangular" height={20} width={'50%'} />
			{!lean && <UaSkeleton variant="rectangular" height={15} width={'25%'} />}
		</>
	)
}

export default SkeletonSlide
