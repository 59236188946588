import { useMemo } from 'react'
import type { HeroContentModule } from '~/lib/client-server/cms/modules/hero'
import styles from './HeroMini.module.scss'
import { isString } from '~/lib/utils'
import ElementTree from '~/components/shared/ElementTree'
import type { Element } from '~/lib/types/coremedia.interface'
import clsx from 'clsx'
import CallsToAction from '~/components/cms/CallsToAction/CallsToAction'
import CmsImage from '../CmsImage/CmsImage'
import FeaturedProducts from '~/components/cms/FeaturedProducts/FeaturedProducts'

export default function HeroMini({ data, locale, index }: { data: HeroContentModule; locale: string; index: number }) {
	const {
		theme,
		image,
		featuredProducts,
		settings,
		hotspots,
		context,
		header,
		subheader,
		mobile,
		snipeText,
		callsToAction,
	} = data

	const priority = index === 0

	const hasImages = !!data.image?.src || !!mobile?.image?.src
	const hasContent = header || subheader || !!callsToAction?.length

	// set width on desktop only
	const cssWidth = useMemo(() => {
		if (!settings?.width || settings.width === 100) return undefined
		return {
			'--desktop-width': `${settings.width}%`,
		} as React.CSSProperties
	}, [settings?.width])

	if (!hasImages && !hasContent) return null
	const headline = settings?.titleStyle

	return (
		<section
			className={`${clsx(styles.hero, {
				[styles.hero__center]: settings?.isCenter,
			})} heromini`}
			data-theme-cms={theme}
		>
			{/* loading both mobile and desktop images, hiding them with css based on mobile/desktop media queries */}
			{hasImages && (
				<div className={styles['hero__img-wrapper']}>
					{image?.src && (
						<CmsImage
							context={context}
							ctaLink={image?.ctaLink}
							image={image}
							hotspots={hotspots}
							priority={priority}
						/>
					)}
					{mobile?.image?.src && (
						<CmsImage
							context={context}
							ctaLink={image?.ctaLink}
							image={mobile?.image}
							hotspots={mobile?.hotspots}
							priority={priority}
							isMobile
						/>
					)}
					{featuredProducts && <FeaturedProducts {...featuredProducts} />}
				</div>
			)}

			{hasContent && (
				<div
					className={clsx(styles.hero__callout, {
						[styles.hero__center]: settings?.isCenter,
					})}
					style={cssWidth}
				>
					{/* Both header and subheader return as a string from mcp, but returns a deeply nested object from coremedia.
				If the header is coming from mcp, we have to check if it is a string to render the proper heading. if not, then it is a coremedia header, and we have to use the ElementTree file to parse data for now. It's not ideal but this is what we have now. */}

					{snipeText && <p className={styles['hero__snipe-text']}>{snipeText}</p>}

					{header && (
						<div className={clsx(styles.hero__header, styles[headline as string])}>
							{isString(header) ? <p>{header}</p> : <ElementTree element={header as Element} locale={locale} />}
						</div>
					)}

					{subheader && (
						<>
							{isString(subheader) ? (
								<p className={`${styles.hero__subheader}`}>{subheader}</p>
							) : (
								<div className={`${styles.hero__subheader}`}>
									<ElementTree element={subheader as Element} locale={locale} />
								</div>
							)}
						</>
					)}
					{!!callsToAction?.length && (
						<CallsToAction
							actions={callsToAction}
							isTextLink={settings?.isTextLinks}
							context={context}
							className={styles.hero__list}
						/>
					)}
				</div>
			)}
		</section>
	)
}
