import { SkeletonSlide } from '~/components/cms/ContentCarousel/CarouselSlide'
import skeletonStyles from './ContentCarouselSkeleton.module.scss'

interface ContentCarouselSkeletonProps {
	slideCount?: number
	className?: string
	lean?: boolean
	style?: 'product' | 'grid'
}
export const ContentCarouselSwiperSkeleton = ({
	slideCount = 3,
	className = '',
	style = 'product',
	lean = false,
}: ContentCarouselSkeletonProps) => {
	return (
		<div
			className={`${className} ${skeletonStyles['skeleton-swiper']} ${
				skeletonStyles[`skeleton-swiper__style-${style}`]
			}`}
		>
			{Array.from({ length: slideCount }, (_, index) => (
				<div className={skeletonStyles['skeleton-slide']} key={index + 1}>
					<SkeletonSlide lean={lean} />
				</div>
			))}
		</div>
	)
}
