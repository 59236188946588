import styles from './CmsImage.module.scss'
import Image from '~/components/primitives/Image'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import { ensureString } from '~/types/strict-null-helpers'
import ConditionalWrapper from '~/components/shared/ConditionalWrapper'
import Hotspots from '~/components/shared/Hotspots/Hotspots'
import { useClickCTAAnalytics } from '~/components/hooks/cms/uci/useClickCTAAnalytics'
import type { ImageReference, AnalyticsContext } from '~/lib/client-server/cms/modules'
import type { Hotspot } from '~/lib/client-server/cms/modules/hotspot'
import mediaQueries from '~/lib/client-only/media-queries'
import { TransparentSource } from '~/components/shared/Picture/TransparentSource'

interface CmsImageProps {
	image: ImageReference
	priority?: boolean
	hotspots?: Hotspot[]
	isMobile?: boolean
	ctaLink?: string
	/** Analytics data for tracking purposes */
	context?: AnalyticsContext
}

const CmsImage = ({ context, ctaLink, priority, image, hotspots, isMobile = false }: CmsImageProps) => {
	const { handleCTAClick } = useClickCTAAnalytics(context)
	if (!image) return null
	const imageClass = isMobile ? styles['image__hide-dt'] : styles['image__hide-mb']
	const transparentMedia = isMobile ? mediaQueries.desktopUp : mediaQueries.tabletDown

	return (
		<>
			<ConditionalWrapper
				condition={!!ctaLink && !hotspots}
				as={LocaleLink}
				href={ensureString(ctaLink)}
				className={`${styles.image} ${imageClass}`}
				onClick={handleCTAClick}
			>
				{/**
				 * This uses the picture element to render a transparent image at a specific media query.
				 * If the media query evaluates to false, then the browser skips it and renders the next element inside the picture, which in this case is the Image component.
				 * More info here: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture#the_media_attribute
				 */}
				<picture>
					<TransparentSource media={transparentMedia} />
					<Image
						src={image.src || ''}
						className={`${styles.image} ${imageClass}`}
						onClick={handleCTAClick}
						alt={image.alt}
						height={image.height}
						width={image.width}
						priority={priority}
						unoptimized
					/>
				</picture>
			</ConditionalWrapper>
			{hotspots && <Hotspots hotspots={hotspots} analytics={image.alt} isMobile={isMobile} />}
		</>
	)
}

export default CmsImage
